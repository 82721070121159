import Overlay from 'sbf-design-system-component/overlays/overlay';

const LOG_TO_CONSOLE = true;
function log () {
  if (LOG_TO_CONSOLE && !!window.console) {
    console.log.apply(console, [].slice.call(arguments));
  }
}

let $visiblePhotoGalleryLi;
let $nextPhotoGalleryLi;
let $prevPhotoGalleryLi;
const $galleryContainer = $('.mypage__photo-gallery');

const photoOverlay = new Overlay($('#mypage_photo_overlay'), {
  full_screen: true
});

const photos = {
  single_photo_overlay: photoOverlay,

  single_photo_overlay_image_tag: $('#mypage_photo_overlay_image'),

  show_photo_overlay: function (url, caption) {
    $nextPhotoGalleryLi = $visiblePhotoGalleryLi.next('li:not(.editable-mypage-ui)');
    $prevPhotoGalleryLi = $visiblePhotoGalleryLi.prev('li');

    if ($nextPhotoGalleryLi.length) {
      $('#show_next_photo').removeClass('hidden');
    } else {
      $('#show_next_photo').addClass('hidden');
    }

    if ($prevPhotoGalleryLi.length) {
      $('#show_prev_photo').removeClass('hidden');
    } else {
      $('#show_prev_photo').addClass('hidden');
    }

    const img = document.createElement('img');
    img.src = url;
    img.onload = function () {
      log(`sizing ${url} appropriately`);

      const { height } = img;
      const { width } = img;

      if (height > width) {
        photos.single_photo_overlay_image_tag.css({
          height: '100%',
          maxHeight: img.height,
          maxWidth: img.width,
          width: 'auto'
        });
      } else {
        photos.single_photo_overlay_image_tag.css({
          width: '100%',
          height: 'auto',
          maxWidth: img.width,
          maxHeight: img.height
        });
      }

      photos.single_photo_overlay_image_tag.attr('src', url);

      $('#photo_overlay_caption').html(caption);

      if (caption === '') {
        // not using 'hidden' because it will need to be visible if
        // the page is editable
        $('.caption-wrapper').addClass('empty');
      } else {
        $('.caption-wrapper').removeClass('empty');
      }

      photos.single_photo_overlay.show();
    };
  },

  get_visible_photo_li: function () {
    return $visiblePhotoGalleryLi;
  },

  get_visible_photo_id: function () {
    return $visiblePhotoGalleryLi.attr('data-id');
  },

  show_next_gallery_photo: function () {
    log('show_next_gallery_photo');
    if ($nextPhotoGalleryLi.length) {
      const $link = $nextPhotoGalleryLi.find('.img-link');
      const fullSizeImageUrl = $link.attr('data-full-size-url');
      const caption = $link.attr('data-caption');
      $visiblePhotoGalleryLi = $nextPhotoGalleryLi;
      photos.show_photo_overlay(fullSizeImageUrl, caption);
    }
  },

  show_prev_gallery_photo: function () {
    log('show_prev_gallery_photo');
    if ($prevPhotoGalleryLi.length) {
      const $link = $prevPhotoGalleryLi.find('.img-link');
      const fullSizeImageUrl = $link.attr('data-full-size-url');
      const caption = $link.attr('data-caption');
      $visiblePhotoGalleryLi = $prevPhotoGalleryLi;
      photos.show_photo_overlay(fullSizeImageUrl, caption);
    }
  }
};

// attach event listeners:

$galleryContainer.on('click', '.img-link', function (click) {
  click.preventDefault();
  log('showing photo overlay...');
  const $this = $(this);

  const fullSizeImageUrl = $this.attr('data-full-size-url');
  const caption = $this.attr('data-caption');
  $visiblePhotoGalleryLi = $this.closest('li');
  photos.show_photo_overlay(fullSizeImageUrl, caption);
});

$('#show_next_photo').on('click', photos.show_next_gallery_photo);
$('#show_prev_photo').on('click', photos.show_prev_gallery_photo);

export default photos;
