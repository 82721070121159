import Overlay from 'sbf-design-system-component/overlays/overlay';
import Gallery from 'modules/gallery';
import 'mypage/sticky-header';
import 'mypage/photos';
import 'mypage/private-event-check';

import MyPageWebSockets from 'websockets/websocket.js';
MyPageWebSockets('event');

// fixing supporters layout if they enter a really long name
// not ideal, but can't come up with a good solution that doesn't
// involve rewriting a ton of CSS
$('#mypage__supporters li').each(function () {
  const $li = $(this);
  const $name = $li.find('.supporters__name');
  // add 8 extra pixels for breathing room
  const originalMargin = parseInt($li.css('margin-bottom'));
  const neededMargin = parseInt($name.height() + 12);
  if (neededMargin > originalMargin) {
    $li.css('margin-bottom', neededMargin);
  }
});

new Overlay($('#primary_event_contact_overlay'), { // eslint-disable-line no-new
  force_solo: true,
  toggles: [$('#primary_event_contact_toggle')]
});

new Overlay($('#secondary_event_contact_overlay'), { // eslint-disable-line no-new
  force_solo: true,
  toggles: [$('#secondary_event_contact_toggle')]
});

const removeBottomBorder = $('.list-nav .white-list').children().length;
if (removeBottomBorder === 1) {
  $('.list-nav .white-list').addClass('contact-overlay-bottom-removal');
}

if ($('.mypage--gallery #mypage_photo_gallery').length) {
  Gallery.containerID = '#mypage_photo_gallery';
  Gallery.setup();
}

// Private event tooltip button - mouse enter event.
$('.flag .flag__body .private-event-tooltip-button').unbind('mouseenter').bind('mouseenter', function () {
  const $this = $(this);
  const $popup = $('.private-event-tooltip-popoup');
  if ($popup.length) {
    const buttonOffset = $this.offset();
    const buttonHeight = $this.height();
    const popupWidth = $popup.width();
    const popupHeight = $popup.height();
    const popupPadding = parseInt($popup.css('padding'));
    $popup.css({
      display: 'block',
      top: buttonOffset.top - popupHeight - (popupPadding * 2) - buttonHeight,
      left: buttonOffset.left - parseInt(popupWidth / 2)
    });
  }
});

// Private event tooltip button - mouse leave event.
$('.flag .flag__body .private-event-tooltip-button').unbind('mouseleave').bind('mouseleave', function () {
  $('.private-event-tooltip-popoup').css('display', 'none');
});
